import React, { useEffect, useState } from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import NestedContentRow from "../components/nestedContentRow";
import ALTile from "../components/ALTile";

import ucVideo from "../images/UC19Video.mp4";
import ucPoster from "../images/uc19Poster.jpg";
import smoothScrollTo from "../components/smoothScroll";

const EventsPage = ({location}) => {
    const eventsElem = location.state;
    const imgData = useStaticQuery(graphql`
        query {
            eventsHeader: file(relativePath: { eq: "EventsHeaderImg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            webinarsimg1_3: file(relativePath: { eq: "webinarsImg1_3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250 maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            webinarsimg2: file(relativePath: { eq: "webinarsImg2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250 maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            webinarsIcon: file(relativePath: { eq: "webinarsIcon.png" }) {
                childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            tradeshowIcon: file(relativePath: { eq: "TradeShowIcon.png" }) {
                childImageSharp {
                    fixed(width: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    const webinarTiles = [
        {
            date: 20200423,
            iconType: "fluid",
            icon: imgData.webinarsimg1_3.childImageSharp.fluid,
            iconAlt: "Smartest Tool in Your Toolbox 1",
            mainText: "Smartest Tool in Your Toolbox",
            subText: "Apr 23, 2020 3:30 PM CDT",
            customBtn:
                <>
                    <div className={"level3"}>
                        <a
                            className={"link btn"}
                            target={"_blank"}
                            href={"https://attendee.gotowebinar.com/register/8244948930313538817"}
                            rel={"noreferrer noopener"}
                        >
                            Register
                        </a>
                    </div>
                    <div className={"webinarIconContainer"}>
                        <Img
                            className={"webinarIcon"}
                            fixed={imgData.webinarsIcon.childImageSharp.fixed}
                            alt={"Webinar Icon"}
                        />
                    </div>
                </>,
            useWebinar: false
        },
        {
            date: 20200507,
            iconType: "fluid",
            icon: imgData.webinarsimg2.childImageSharp.fluid,
            iconAlt: "Smartest Tool in Your Toolbox 2",
            mainText: "Smartest Tool in Your Toolbox",
            subText: "May 7, 2020 2:00 PM CDT",
            customBtn:
                <>
                    <div className={"level3"}>
                        <a
                            className={"link btn"}
                            target={"_blank"}
                            href={"https://attendee.gotowebinar.com/register/7029261805650309904"}
                            rel={"noreferrer noopener"}
                        >
                            Register
                        </a>
                    </div>
                    <div className={"webinarIconContainer"}>
                        <Img
                            className={"webinarIcon"}
                            fixed={imgData.webinarsIcon.childImageSharp.fixed}
                            alt={"Webinar Icon"}
                        />
                    </div>
                </>,
            useWebinar: false
        },
        {
            date: 20200512,
            iconType: "fluid",
            icon: imgData.webinarsimg1_3.childImageSharp.fluid,
            iconAlt: "Grid Time Entry Webinar",
            mainText: "Grid Time Entry",
            subText: "May 12, 2020 10:00 AM CDT",
            customBtn:
                <>
                    <div className={"level3"}>
                        <a
                            className={"link btn"}
                            target={"_blank"}
                            href={"https://attendee.gotowebinar.com/register/6271127647578020112"}
                            rel={"noreferrer noopener"}
                        >
                            Register
                        </a>
                    </div>
                    <div className={"webinarIconContainer"}>
                        <Img
                            className={"webinarIcon"}
                            fixed={imgData.webinarsIcon.childImageSharp.fixed}
                            alt={"Webinar Icon"}
                        />
                    </div>
                </>,
            useWebinar: false
        },
    ];
    const tradeShowTiles = [
        {
            iconData: {
                icon: imgData.tradeshowIcon.childImageSharp.fixed,
                iconType: "fixed",
                iconAlt: "TradeShows Icon",
                iconCaption: "Tradeshow"
            },
            textData: {
                level1:
                    <a
                        className={"link btn"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                        href={"http://cgc2017.wpengine.com/about/customer-focus/"}
                    >
                        CGC Customer Focus User Conference
                    </a>,
                level2: "July 21 - 24",
                level3: "The Phoenician Resort & Spa – Scottsdale, AZ"
            },
        },
        {
            iconData: {
                icon: imgData.tradeshowIcon.childImageSharp.fixed,
                iconType: "fixed",
                iconAlt: "TradeShows Icon",
                iconCaption: "Tradeshow"
            },
            textData: {
                level1:
                    <a
                        className={"link btn"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                        href={"https://collaborate.viewpoint.com/"}
                    >
                        Viewpoint User Conference Collaborate
                    </a>,
                level2: "September 21-24",
                level3: "Oregon Convention Center – Portland, Oregon"
            }
        },
        {
            iconData: {
                icon: imgData.tradeshowIcon.childImageSharp.fixed,
                iconType: "fixed",
                iconAlt: "TradeShows Icon",
                iconCaption: "Tradeshow"
            },
            textData: {
                level1:
                    <a
                        className={"link btn"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                        href={"https://www.necaconvention.org/#/page/landing"}
                    >
                        NECA Convention
                    </a>,
                level2: "October 3 - 6",
                level3: "Lakeside Center - McCormick Place, Chicago, IL"
            },
            link: <div className={"tradeShowBooth"}>Booth #1624</div>
        },
        {
            iconData: {
                icon: imgData.tradeshowIcon.childImageSharp.fixed,
                iconType: "fixed",
                iconAlt: "TradeShows Icon",
                iconCaption: "Tradeshow"
            },
            textData: {
                level1:
                    <a
                        className={"link btn"}
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                        href={"https://conference.cfma.org/"}
                    >
                        CFMA's Annual Conference and Exhibition
                    </a>,
                level2: "May 15 - 19, 2021",
                level3: "Gaylord Rockies Resort & Convention Center in Denver, Colorado"
            },
        }
    ];
    const [headerImgVisible, isHeaderVisible] = useState(false);
    const scrollTo = pageID => {
        const scrollTop = document.getElementById(pageID);
        if(scrollTop && scrollTop.offsetTop) {
            smoothScrollTo(scrollTop.offsetTop, 300);
        }
    };
    const toggleHeaderImage = _ => {
        if(window.innerWidth <= 1366) {
            return isHeaderVisible(false);
        }
        isHeaderVisible(true);
    };

    const formatDate = date => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('');
    };

    const setLiveWebinar = (webinarDate) => {
        const today = formatDate(new Date());
        return (webinarDate === parseInt(today));
    };

    useEffect( _ => {
        if(eventsElem && eventsElem.component) {
            scrollTo(eventsElem.component);
        }
        toggleHeaderImage();
        window.addEventListener("resize", toggleHeaderImage);
        return function () {
            window.removeEventListener("resize", toggleHeaderImage);
        }
    });

    return (
        <Layout pageID={"events"}>
            <SEO
                title="Events"
                description={"Watch LIVE webinars, see what tradeshows and conferences we’ll be at next, and get updates on our upcoming ALMobile Users Conference."}
            />
            <div className="contentRow pageHeader">
                <div className="subContentRow">
                    <NestedContentRow
                        iconData={
                            (headerImgVisible) ?
                                {
                                    iconType: "fluid",
                                    icon: imgData.eventsHeader.childImageSharp.fluid,
                                    iconAlt: "Events Header"
                                }
                                :
                                {}
                        }
                        textData={{
                            level1: "EVENTS",
                            level2: "Connect With Us, We're Here for You",
                            level3: <div className={"level3"}>
                                <Link className={"link btn"} to={`/almobiletimetracking/`}>See Our Product</Link>
                            </div>
                        }}
                    />
                </div>
            </div>
            <div id={"webinars"} className={"contentRow"}>
                <div className={"subContentRow"}>
                    <div className={"webinarsHeader header"}>Upcoming Webinars and On-Demand Webinars</div>
                    <div className={"webinarsTiles"}>
                        {
                            webinarTiles.map((webinarData, index) => {
                                return (
                                    <ALTile
                                        key={index}
                                        className={`webinar${index + 1} ${setLiveWebinar(webinarData.date) ? "live" : ""}`}
                                        {...webinarData}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div id={"tradeShowsConferences"} className={"contentRow"}>
                <div className={"subContentRow"}>
                    <div className={"tradeShowHeader header"}>TradeShows and Conferences</div>
                    <div className={"tradeShowTiles"}>
                        {
                            tradeShowTiles.map((elem, index) => {
                                return(
                                    <NestedContentRow
                                        key={index}
                                        {...elem}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div id={"usersConferences"} className={"contentRow"}>
                <div className={"subContentRow"}>
                    <div className={"usersConferenceHeader header"}>ALMobile User Events</div>
                    <NestedContentRow
                        textData={{
                            level1: "ALMobile UC21",
                            level2: "March 1-2, 2021 | Denton, TX",
                            level3: `During the ALMobile 2021 Users Conference, you and your
                                    company’s users will be launched to the next level of speed
                                    and savings as you hone your skills and knowledge of how
                                    to utilize ALMobile. Several of our clients will speak during
                                    the training sessions that are facilitated by an Austin Lane
                                    team member. Here, you can share your experiences with
                                    other ALMobile users, and you and your team will learn
                                    even more from our team of experts.`
                        }}
                        videoData={{
                            videoSrc: ucVideo,
                            videoPoster: ucPoster
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
};

export default EventsPage;